//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary:       									if(isDarkMode(), #222222, #333333);
$primary-active:    								if(isDarkMode(), #333333, #222222);
$primary-light:    								    if(isDarkMode(), #F4F4F4, #F4F4F4);
$primary-inverse:  									#FFFFFF;

// Success
$success:       									if(isDarkMode(), #0BB783, #50CD89);
$success-active:    								if(isDarkMode(), #04AA77, #47BE7D);
$success-light:    									if(isDarkMode(), #1C3238, #E8FFF3);
$success-inverse:  									#FFFFFF;

// Info
$info:       									    if(isDarkMode(), #5255AA, #1F2041);
$info-active:    									if(isDarkMode(), #7476BC, #181831);
$info-light:    									if(isDarkMode(), #2F264F, #F8F5FF);
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    if(isDarkMode(), #F64E60, #F1416C);
$danger-active:    									if(isDarkMode(), #EE2D41, #D9214E);
$danger-light:    									if(isDarkMode(), #3A2434, #FFF5F8);
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									if(isDarkMode(), #FFA800, #FFC700);
$warning-active:    								if(isDarkMode(), #EE9D01, #F1BC00);
$warning-light:    									if(isDarkMode(), #392F28, #FFF8DD);
$warning-inverse:  									#FFFFFF;

// INPUT GROUP PREPEND OVERRIDE
$input-group-addon-padding-y: 1rem;
