//
// Menu
//

// Aside menu
.aside-menu {
    .menu {
        // Link padding x
        @include menu-link-padding-x(get($aside-config, padding-x));

        // Menu indention
        @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));
    }

    // Item
    .menu-item {
        padding: 0;

        // Menu Link
        .menu-link {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            .menu-title{
              color: var(--navigation_color);
            }
        }

        // Icon
        .menu-icon {
          width: 35px;
          margin-right: 20px;
          height: 35px;
          i{
            position: relative;
            top:2px;
          }
          border-radius: 0.5rem;
          justify-content: center;
          align-items: center;
        }

      .menu-link:hover{
        .menu-icon{
          i{
            color: var(--primary) !important;
          }
        }
      }

      .menu-link.active:hover{
        .menu-icon{
          i{
            color: var(--theme-secondary-darker) !important;
          }
        }
      }

      .active{
        .menu-icon {
          background-color: var(--primary);
        }
        .menu-title{
          color: var(--navigation_selected_color) !important;
        }
      }

    }
}

// Aside dark theme
.aside-dark {
    .hover-scroll-overlay-y {
        --scrollbar-space: 0.4rem;

        @include scrollbar-color(get($aside-config, scrollbar-color), get($aside-config, scrollbar-hover-color));
    }

    .menu {
        .menu-item {
            .menu-section {
                font-weight: 400;
                color: var(--primary);
                letter-spacing: 0;
            }

            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state( #afc5b6, #cccccc, #9899ac, #9899ac, null);
            @include menu-link-hover-state( $white, $white, $primary, $white, $primary);
            @include menu-link-show-state( $white, $white, $primary, $white, $primary);
            @include menu-link-active-state( $white, $white, $primary, $white, $primary);
        }
    }
}

// Aside light theme
.aside-light {
    .menu {
      .menu-section {
        font-weight: 500;
        color: var(--primary);
        letter-spacing: 0;
        display: block;
        padding-bottom: 10px;
        border-bottom: 1px solid;
        border-bottom-color: rgba(255,255,255,0.2);
      }

      .menu-title {
            font-weight: 500;
            letter-spacing: 0px;
            color:#6B7280 !important;
        }
    }
}
