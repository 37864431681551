@font-face {
    font-family: "menuicon";
    src: url("./menu-icon.ttf?999ce2bc03165411c64889f1b9680292") format("truetype"),
url("./menu-icon.woff?999ce2bc03165411c64889f1b9680292") format("woff"),
url("./menu-icon.woff2?999ce2bc03165411c64889f1b9680292") format("woff2"),
url("./menu-icon.eot?999ce2bc03165411c64889f1b9680292#iefix") format("embedded-opentype"),
url("./menu-icon.svg?999ce2bc03165411c64889f1b9680292#menuicon") format("svg");
}

i[class^="menuicon-"]:before, i[class*=" menuicon-"]:before {
    font-family: menuicon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.menuicon-dashboard:before {
    content: "\f101";
}
.menuicon-success-1:before {
    content: "\f102";
}
.menuicon-heart-1:before {
    content: "\f103";
}
.menuicon-campaign:before {
    content: "\f104";
}
.menuicon-accounting:before {
    content: "\f105";
}
.menuicon-graphic-design:before {
    content: "\f106";
}
.menuicon-settings:before {
    content: "\f107";
}
.menuicon-credit-card:before {
    content: "\f108";
}
