@font-face {
    font-family: "qricon";
    src: url("./qricon.ttf?0f7c6e34279021eeeb3bbbfe5f466c65") format("truetype"),
url("./qricon.woff?0f7c6e34279021eeeb3bbbfe5f466c65") format("woff"),
url("./qricon.woff2?0f7c6e34279021eeeb3bbbfe5f466c65") format("woff2"),
url("./qricon.eot?0f7c6e34279021eeeb3bbbfe5f466c65#iefix") format("embedded-opentype"),
url("./qricon.svg?0f7c6e34279021eeeb3bbbfe5f466c65#qricon") format("svg");
}

i[class^="qricon-"]:before, i[class*=" qricon-"]:before {
    font-family: qricon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.qricon-qr-code:before {
    content: "\f101";
}
.qricon-payment:before {
    content: "\f102";
}
.qricon-qr-code-scan:before {
    content: "\f103";
}
.qricon-knock:before {
    content: "\f104";
}
.qricon-subscription-model:before {
    content: "\f105";
}
.qricon-zakat:before {
    content: "\f106";
}
.qricon-smartphone:before {
    content: "\f107";
}
.qricon-sad:before {
    content: "\f108";
}
.qricon-unknown:before {
    content: "\f109";
}
.qricon-fundraising:before {
    content: "\f10a";
}
.qricon-fundraising-1:before {
    content: "\f10b";
}
