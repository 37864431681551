//
// Form Select
//

.form-select {
    box-shadow: none !important;

    // Solid style
	&.form-select-solid {
		@include placeholder($input-solid-placeholder-color);
		background-color: $input-solid-bg;
		border-color: $input-solid-bg;
		color: $input-solid-color;
		transition: $transition-input;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $input-solid-bg-focus;
			border-color: $input-solid-bg-focus !important;
			color: $input-solid-color;
			transition: $transition-input;
		}
	}

	&.form-select-primary {
		@include placeholder($primary-light);
		background-color: $primary;
		border-color: $primary-light;
		color: $primary-light;
		transition: $transition-input;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $primary;
			border-color: $primary-light !important;
			color: $primary-light;
			transition: $transition-input;
		}
	}

	// White style
	&.form-select-white {
		@include placeholder($input-placeholder-color);
		background-color: $body-bg;
		border-color: $body-bg;
		color: $input-color;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $body-bg;
			border-color: $body-bg !important;
			color: $input-color;
		}
	}
}
